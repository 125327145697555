import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import DropDown from '../../common/DropDown';
import style from './header.scss';
import logo from '../../../assets/images/bellabona-logo.svg';
import { logOut } from '../../../api/auth';
import { PAYMENT_TYPE_B } from '../../../constants/orderConstants';
import Intro from '../../common/Intro';
import { getCurrencyString } from '../../../utils';

const Header = ({ showDropdown, userInfo }) => {
	const { t } = useTranslation();
	const [fancyClass, setFancyClass] = useState(true);
	const [showIntro, setShowIntro] = useState(false);
	const history = useHistory();
	const ordersPath = useRouteMatch({ path: '/orders/:deliveryDate?' });
	const onClickLogoutHandler = () => {
		logOut(history);
	};
	const dropDownLabel = () => (
		<>
			<div className='hide-xs'>{userInfo?.username || userInfo?.email}</div>
			<div className={`${style.profile_icon} hide-sm hide-md hide-lg`}>
				<i className='icon-person' />
			</div>
		</>
	);

	const onSteps = () => {
		history.push('/profile');
		window.location.reload();
	};

	useEffect(() => {
		if (userInfo) {
			setTimeout(() => {
				if (
					userInfo?.isIntroDone === 0 &&
          document.getElementsByClassName('user_wallet').length
				) {
					setShowIntro(true);
				}
			}, 100);
		}
	}, [userInfo]);
	return (
		<>
			<header className={`${style.header} ${fancyClass && style.fancy_header}`}>
				<div className='container'>
					<div className={`${style.header_row} row middle-xs`}>
						<div className='col-xs-offset-3  col-xs-6'>
							<div className={`${style.logo}`}>
								<Link to={ordersPath?.url || '/orders'}>
									<img src={logo} alt='Bella & Bona' />
								</Link>
							</div>
						</div>
						<div className='col-xs-3 end-xs'>
							{showDropdown && userInfo && (
								<>
									<DropDown
										label={dropDownLabel()}
										boxAlign='right'
										className={style.user_dropdown}
										activeClass={style.dropdown_active}
										userInfo={userInfo}
									>
										<ul className={style.user_links}>
											{userInfo?.branches?.[0]?.branch.paymentType ===
                        PAYMENT_TYPE_B && (
												<li className='user_wallet'>
													{showIntro &&
                          window.location.pathname !== '/profile' &&
                          !userInfo?.isIntroDone ? (
															<Intro
																steps={0}
																onSteps={onSteps}
																options={{ doneLabel: t('Next') }}
															/>
														) : null}
													<span>
														<Link to='/profile' className={style.droptown_item}>
															<i className='icon-wallet' />
															{t('Balance')}
:
															{' '}
															{getCurrencyString(userInfo?.wallet?.balance || 0)}
														</Link>
													</span>
												</li>
											)}
											<li>
												<Link to='/profile' className={style.droptown_item}>
													<i className='icon-person_outline' />
													{t('Profile')}
												</Link>
											</li>
											<li>
												<Link to='/past-orders' className={style.droptown_item}>
													<i className='icon-order_history' />
													{t('Past Orders')}
												</Link>
											</li>
											<li>
												<div
													className={style.droptown_item}
													role='presentation'
													onClick={onClickLogoutHandler}
												>
													<i className='icon-logout' />
													{t('Sign out')}
												</div>
											</li>
										</ul>
									</DropDown>
								</>
							)}
						</div>
					</div>
				</div>
			</header>
			<Waypoint
				onEnter={() => setFancyClass(!fancyClass)}
				onLeave={() => setFancyClass(!fancyClass)}
			/>
		</>
	);
};
Header.propTypes = {
	showDropdown: PropTypes.bool
};
Header.defaultProps = {
	showDropdown: false
};
export default Header;
