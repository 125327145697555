import moment from 'moment-timezone';
import {
	ORDER_PLACEMENT_THRESHOLD_HOUR,
	WORKING_DAYS,
	YYYY_MM_DD,
	URL_DATE_STRING
} from '../../constants/dayDateConstants';

export const getTimeInSeconds = timeInMilliSeconds => {
	if (!timeInMilliSeconds || (typeof timeInMilliSeconds !== 'number' && 
		typeof timeInMilliSeconds !== 'string' && typeof timeInMilliSeconds !== 'bigint')) {
		return null;
	}
	
	if (typeof timeInMilliSeconds === 'string') {
		const timeInMs = parseInt(timeInMilliSeconds, 10);
		if (Number.isNaN(timeInMs)) {
			return null;
		}

		return Math.round(timeInMs / 1000);
	}

	if (typeof timeInMilliSeconds === 'bigint') {
		const timeNumber = Number(timeInMilliSeconds);
		if (Number.isNaN(timeNumber)) {
			return null;
		}

		return Math.round(timeNumber / 1000);
	}

	return Math.round(timeInMilliSeconds / 1000);
};

export const getMomentObject = timeInMilliSeconds => moment
	.unix(getTimeInSeconds(timeInMilliSeconds));

export const checkOrderClosingTime = (cutOffTime, serverTime, deliveryDate) => {
	const currentTime = getMomentObject(serverTime);

	const branchCutOffTime = getMomentObject(cutOffTime)?.utc();
	const branchCloseHour = branchCutOffTime?.hour() || ORDER_PLACEMENT_THRESHOLD_HOUR;
	const branchCloseMinutes = branchCutOffTime?.minute() || 0;
	const branchCloseSeconds = branchCutOffTime?.second() || 0;
	
	const closingTime = currentTime
		.clone()
		.hour(branchCloseHour)
		.minutes(branchCloseMinutes)
		.seconds(branchCloseSeconds);
	return (deliveryDate?.isSame(closingTime, 'd') && currentTime?.isSameOrAfter(closingTime));
};

export const isValidDeliveryDate = (
	deliveryDate,
	serverTime,
	validLastDate,
	workingDays = WORKING_DAYS,
	blackoutDates = []
) => {
	if (
		deliveryDate && 
		moment(deliveryDate, URL_DATE_STRING)?.isValid() && 
		workingDays?.indexOf(deliveryDate?.format('dddd')) !== -1 &&
		blackoutDates?.indexOf(deliveryDate.format(YYYY_MM_DD)) === -1
	) {
		return deliveryDate.isBetween(serverTime, validLastDate, 'd', '[]');
	}
	return false;
};

export const getNewDeliveryDate = (
	serverTime, workingDays = WORKING_DAYS, cutOffTime, blackoutDates = []
) => {
	const newDay = serverTime?.clone();
	const isClosed = checkOrderClosingTime(cutOffTime, serverTime?.valueOf(), serverTime);
	// increment the date
	// if server time exceeds cutOffTime
	// if delivery date is not valid working day
	// if delivery date is listed in blackout date
	if (isClosed || 
		workingDays.indexOf(newDay.format('dddd')) === -1 ||
		blackoutDates.indexOf(newDay.format(YYYY_MM_DD)) !== -1
	) {
		// Donot shufle the order. 
		if (isClosed) {
			newDay.add(1, 'day');
		}
		while (
			workingDays.indexOf(newDay.format('dddd')) === -1 || 
			blackoutDates?.indexOf(newDay.format(YYYY_MM_DD)) !== -1
		) {
			newDay.add(1, 'day');
		}
		return newDay.startOf('day').add(12, 'hour');
	}
	
	return serverTime.clone().startOf('day').add(12, 'hour');
};
export const getPreviousDeliveryDate = (
	serverTime, workingDays = WORKING_DAYS, blackoutDates = []
) => {
	const newDay = serverTime?.clone().subtract(1, 'day');
	
	// decrement the date
	// if delivery date is not valid working day
	// if delivery date is listed in blackout date
	if (
		workingDays.indexOf(newDay.format('dddd')) === -1 ||
		blackoutDates.indexOf(newDay.format(YYYY_MM_DD)) !== -1
	) {
		while (
			workingDays.indexOf(newDay.format('dddd')) === -1 || 
			blackoutDates?.indexOf(newDay.format(YYYY_MM_DD)) !== -1
		) {
			newDay.subtract(1, 'day');
		}
		return newDay.startOf('day').add(12, 'hour');
	}
	
	return newDay.clone().startOf('day').add(12, 'hour');
};
