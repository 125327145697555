import React, {
	useState, memo, useRef, useEffect 
} from 'react';
import PropTypes from 'prop-types';
import ClickOutside from '../ClickOutside';
import style from './drop-down.scss';
import { PAYMENT_TYPE_B } from '../../../constants/orderConstants';

const DropDown = ({
	label,
	refId,
	children,
	boxAlign,
	className,
	activeClass,
	userInfo
}) => {
	const [active, setActive] = useState(
		!!(
			!userInfo?.isIntroDone &&
      userInfo?.branches?.[0]?.branch?.paymentType === PAYMENT_TYPE_B &&
      window.location.pathname.includes('/orders')
		)
	);
	useEffect(() => {
		setActive(
			!!(
				!userInfo?.isIntroDone &&
        userInfo?.branches?.[0]?.branch?.paymentType === PAYMENT_TYPE_B &&
        window.location.pathname.includes('/orders')
			)
		);
	}, [userInfo]);
	const wrapperRef = useRef(refId);
	const onClickHandler = () => {
		setActive(false);
	};
	const boxAlignClass = style[`${boxAlign}`] || '';
	return (
		<div
			ref={wrapperRef}
			className={`${style.option_wrapper} ${active && style.active} ${
				active && activeClass
			} ${boxAlignClass} ${className}`}
		>
			<div
				role='presentation'
				className={style.label_wrapper}
				onClick={() => setActive(!active)}
			>
				<span className={style.label}>{label}</span>
				<i className='icon-expand_more' />
			</div>
			{active && (
				<ClickOutside
					callbackHandler={() => setActive(false)}
					wrapperRef={wrapperRef}
				>
					<div
						role='presentation'
						className={style.options}
						onClick={onClickHandler}
					>
						{children}
					</div>
				</ClickOutside>
			)}
		</div>
	);
};

DropDown.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	boxAlign: PropTypes.oneOf(['left', 'right']),
	className: PropTypes.string,
	refId: PropTypes.string
};
DropDown.defaultProps = {
	label: '',
	className: '',
	boxAlign: 'left',
	refId: null
};
export default memo(DropDown);
