const routes = [
	{
		path: '/login',
		name: 'login',
		component: () => import(
			/* webpackPreload: true, webpackChunkName: "register-layout" */ './layout/SignIn'
		),
		exact: true,
		lazyLoad: true
	},
	{
		path: '/forgot-password',
		name: 'forgot-password',
		component: () => import(
			/* webpackChunkName: "register-layout" */ './layout/ForgotPassword'
		),
		exact: true,
		lazyLoad: true
	},
	{
		path: '/resetpassword/:resetToken?',
		name: 'resetpassword',
		component: () => import(
			/* webpackChunkName: "register-layout" */ './layout/NewPassword'
		),
		exact: false,
		lazyLoad: true
	},
	{	
		path: '/verifyemail/:email/:verifytoken',
		name: 'resetpassword',
		component: () => import(
			/* webpackChunkName: "register-layout" */ './layout/VerifyEmail'
		),
		exact: false,
		lazyLoad: true
	},
	{
		path: '/signup',
		name: 'signup',
		component: () => import(/* webpackChunkName: "register-layout" */ './layout/SignUp'),
		exact: true,
		lazyLoad: true
	},
	{
		path: '/shared-menu',
		name: 'menu',
		component: () => import(/* webpackChunkName: "register-layout" */ './layout/Menu'),
		exact: true,
		lazyLoad: true,
		meta: {
			authRequire: false
		}
	},
	{
		path: '/catering-menu',
		name: 'menu',
		component: () => import(/* webpackChunkName: "register-layout" */ './layout/Catering'),
		exact: true,
		lazyLoad: true,
		meta: {
			authRequire: false
		}
	},
	{
		path: '/',
		name: 'home',
		exact: true,
		meta: {
			withLayout: true,
			authRequire: true
		},
		childRoutes: [
			{
				path: '/orders/:selectedDate?',
				name: 'orders',
				component: () => import(
					/* webpackPreload: true, webpackChunkName: "orders" */ './layout/Orders'
				),
				lazyLoad: true,
				exact: true,
				meta: {
					authRequire: true
				}
			},
			{
				path: '/past-orders',
				name: 'pastOrders',
				component: () => import(
					/* webpackPreload: true, webpackChunkName: "orders" */ './layout/PastOrders'
				),
				lazyLoad: true,
				exact: true,
				meta: {
					authRequire: true
				}
			},
			{
				path: '/profile',
				name: 'Profile',
				component: () => import(
					/* webpackChunkName: "profile" */ './layout/Profile'
				),
				lazyLoad: true,
				exact: true,
				meta: {
					authRequire: true
				}
			},
			{
				path: '/payment-success',
				name: 'Profile',
				component: () => import(
					/* webpackChunkName: "profile" */ './layout/PaymentSuccess'
				),
				lazyLoad: true,
				exact: true,
				meta: {
					authRequire: true
				}
			},
			{
				path: '*',
				// exact: true,
				name: 'notfound',
				lazyLoad: true,
				component: () => import(
					/* webpackChunkName: "notfound" */ './layout/PageNotFound'
				)
			}
		]
	}
];
export default routes;
