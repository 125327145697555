import apiHelper from '../apiHelper';

export const getUsersMe = async () => {
	const getUsersMeUrl = 'users/me/profile';
	const getOptions = {};

	try {
		const response = await apiHelper.get(getUsersMeUrl, getOptions);
		return response.result;
	} catch (error) {
		return error;
	}
};
export const getUserAllowance = async YYYY_MM_DD => {
	const alowanceUrl = `/users/allowance/${YYYY_MM_DD}`;
	try {
		const response = await apiHelper.get(alowanceUrl);
		return response?.result;
	} catch (error) {
		return error;
	}
};

export const getStockDataForToday = async YYYY_MM_DD => {
	const stockForTodayUrl = `/dishes/stockfortoday/${YYYY_MM_DD}`;
	try {
		const response = await apiHelper.get(stockForTodayUrl);
		return response?.result;
	} catch (error) {
		return error;
	}
};

export const switchBranch = async id => {
	const switchBranchUrl = `/users/switchbranch/${id}`;
	try {
		const response = await apiHelper.get(switchBranchUrl);
		return response?.result;
	} catch (error) {
		return error;
	}
};

export const getUsersPaymentStatus = async customerId => {
	const getUsersPaymentStatusUrl = `users/payments/failed/${customerId}`;
	const getOptions = {
		// crossDomain: true
	};

	try {
		const response = await apiHelper.get(getUsersPaymentStatusUrl, getOptions);
		return response.result;
	} catch (error) {
		return error;
	}
};

export const updateProfileAPI = async (userId, requestBody) => {
	try {
		const response = await apiHelper.put(`/users/update/profile/${userId}`, {
			requestBody
		});
		return response?.result;
	} catch (error) {
		return error;
	}
};

export const changeUserPassword = async (userId, currentPassword, password) => {
	const requestBody = {
		currentPassword,
		password
	};

	try {
		const response = await apiHelper.post(`/users/profile/change/password/${userId}`, {
			requestBody
		});
		return response?.result;
	} catch (error) {
		return error;
	}
};

export const resetPassword = async password => {
	const requestBody = {
		password
	};

	try {
		const response = await apiHelper.put('/users/profile/reset/password', {
			requestBody
		});
		return response?.result;
	} catch (error) {
		return error;
	}
};

export const resetPasswordSetToken = async token => {
	try {
		const response = await apiHelper.get(`/auth/resetpassword/${token}`);
		return response?.result;
	} catch (error) {
		return error;
	}
};

export const createUserWallet = async userId => {
	const requestBody = {
		userId
	};
	try {
		const response = await apiHelper.post('/wallet', {
			requestBody
		});
		return response?.result;
	} catch (error) {
		return error;
	}
};

export const getCreditTypes = async () => {
	try {
		const response = await apiHelper.get('/credits');
		return response?.result;
	} catch (error) {
		return error;
	}
};

export const addCreditTransactionLog = async requestBody => {
	try {
		const response = await apiHelper.post('/credits/log', {
			requestBody
		});
		return response?.result;
	} catch (error) {
		return error;
	}
};

export const updateUserWallet = async (walletId, requestBody) => {
	try {
		const response = await apiHelper.put(`/wallet/${walletId}`, {
			requestBody
		});
		return response?.result;
	} catch (error) {
		return error;
	}
};

export const createStripeCheckoutSession = async (amount, cancelUrl, metadata) => {
	const requestBody = {
		amount,
		cancelUrl,
		metadata
	};
	try {
		const response = await apiHelper.post('/wallet/stripe/create-checkout-session', {
			requestBody
		});
		return response?.result;
	} catch (error) {
		return error;
	}
};

export const createPaypalCheckoutSession = async ({ payable, walletId, dishElementId }) => {
	try {
		const response = await apiHelper.get(`/paypal/initiate/${payable}/${walletId}`, {
			params: {
				cancelUrl: `${window?.location?.origin}${window?.location?.pathname}${
					dishElementId ? `#${dishElementId}` : ''
				}`
			}
		});
		return response?.result;
	} catch (error) {
		return error;
	}
};

export const getWalletTransaction = async () => {
	try {
		const response = await apiHelper.get('/wallet/log');
		return response?.result;
	} catch (error) {
		return error;
	}
};

export const createNpsReview = async review => {
	const requestBody = review;
	try {
		const response = await apiHelper.post('/nps', { requestBody });

		if (response?.result?.length) {
			const data = response?.result;
			return data;
		}

		return null;
	} catch (err) {
		return { message: err?.error?.data?.message, messageType: 'error' };
	}
};
