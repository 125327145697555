import moment from 'moment-timezone';
import {
	GENERIC_STICKER,
	GENERIC_STICKER_THUMBNAIL,
	STICKER_IMAGE_URL,
	STICKER_THUMBNAIL_IMAGE_URL
} from '../constants/urlConfig';
import { PAYMENT_TYPE_B, CC_TYPE_A } from '../constants/orderConstants';

export const RegEx = {
	email: /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+?))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/,
	// password: /^(?=.*\d)(?=.*[a-zA-Z]).{5,15}$/,
	userName: /^[a-zA-Z]{2,}[a-zA-Z0-9_\s]*[a-zA-Z0-9]$/g
};

// TODO: use inner object
export const getRegEx = type => {
	const regExObj = {
		email: /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+?))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/,
		// password: /^(?=.*\d)(?=.*[a-zA-Z]).{5,15}$/,
		userName: /^[a-zA-Z]{2,}[a-zA-Z0-9_\s]*[a-zA-Z0-9]$/g
	};
	const regEx = regExObj[`${type}`];
	return regEx;
};

export const validationHelper = formValues => {
	const errorKeys = [];
	const formItems = Object.keys(formValues);
	const emailRegEx = getRegEx('email');
	const nameRegEx = getRegEx('userName');
	let validationMsg = {};

	formItems?.forEach(entry => {
		if (entry === 'email' && !emailRegEx.test(formValues.email)) {
			validationMsg = {
				...validationMsg,
				emailErr: 'Please enter valid email Id'
			};
			errorKeys.push(entry);
		}
		if (entry === 'username' && ((formValues?.username?.trim()?.length > 20) || !nameRegEx.test(formValues?.username))) {
			if (!(/^[a-zA-Z]{2,}/g.test(formValues?.username))) {
				validationMsg = {	
					...validationMsg,
					nameErr: 'Invalid username. Username must start with atleast 2 letters'
				};	
			} else if (!(/[a-zA-Z0-9]$/g.test(formValues?.username))) {
				validationMsg = {	
					...validationMsg,
					nameErr: 'Invalid username. Username must end with alphanumeric character.'
				};
			} else {
				validationMsg = {	
					...validationMsg,
					nameErr: 'Invalid username. Allowed characters are a-z, A-Z, _, and 0-9'
				};
			}
			errorKeys.push(entry);
		}
		if (entry === 'password' && !formValues.password) {
			validationMsg = {
				...validationMsg,
				passwordErr: 'Please enter password'
			};
			errorKeys.push(entry);
		}
		if (
			(entry === 'newPassword1' || entry === 'newPassword2') &&
      (!formValues.newPassword1 ||
        !formValues.newPassword2 ||
        formValues.newPassword1 !== formValues.newPassword2)
		) {
			validationMsg = {
				...validationMsg,
				passwordErr: 'Please enter the same password in both password fields'
			};
			errorKeys.push(entry);
		}
		if (entry === 'currentPassword' && !formValues.currentPassword) {
			validationMsg = {
				...validationMsg,
				currentPasswordError: 'Please enter your current password'
			};
		}
	});
	const isValidData = errorKeys.length === 0;
	return { validationMsg, isValidData };
};

export const getStartAndEndOfDay = (startDate, endDate) => {
	const endDateFB = endDate || startDate;
	const startDateUnix = startDate.startOf('day').valueOf();
	const endDateUnix = endDateFB.endOf('day').valueOf();
	return { startDateUnix, endDateUnix };
};

export const getStickerUrl = (categoryCode, categoryNumber, isThumbnail) => {
	if (categoryCode === 'AD') {		
		if (isThumbnail) {
			return GENERIC_STICKER_THUMBNAIL;
		}			
		return GENERIC_STICKER;
	}
	const stickerUrl = isThumbnail ?
		STICKER_THUMBNAIL_IMAGE_URL :
		STICKER_IMAGE_URL;
	return stickerUrl		
		.replace('{{NUM}}', categoryNumber);
};

export const checkCardConnected = userInfo => {
	const branch = userInfo?.branches?.[0]?.branch;
	return (
		branch?.paymentType === PAYMENT_TYPE_B &&
    !!userInfo &&
    userInfo?.paymentType === CC_TYPE_A
	);
};

export const filterOrdersByDate = (ordersData, deliveryDate) => ordersData?.filter(order => deliveryDate.isSame(order?.deliveryDate, 'd'));

export const getCurrencyString = (val = 0) => {
	let localString;
	if (!Number.isNaN(Number(val))) {
		localString = val?.toLocaleString('de-DE', {
			style: 'currency',
			currency: 'EUR'
		});
	}
	return localString;
};

// Refactor required -  sortArrayByCreatedDate and sortArrayByDeliveryDate - same functionality
export const sortArrayByCreatedDate = (a, b) => {
	if (moment(a.createdAt).isBefore(moment(b.createdAt))) {
		return 1;
	}
	if (moment(a.createdAt).isAfter(moment(b.createdAt))) {
		return -1;
	}
	return 0;
};

export const sortArrayByDeliveryDate = (a, b) => {
	if (moment(a.deliveryDate).isBefore(moment(b.deliveryDate))) {
		return 1;
	}
	if (moment(a.deliveryDate).isAfter(moment(b.deliveryDate))) {
		return -1;
	}
	// If delivery dates are same, sort on createdAt time - sortArrayByCreatedDate()
	return sortArrayByCreatedDate(a, b);
};

export const sortArrayById = (a, b) => {
	if (a.id > b.id) {
		return 1;
	}
	if (a.id < b.id) {
		return -1;
	}
	return 0;
};

export const accumulateOrdersById = ordersArray => {
	const sortedOrdersArray = ordersArray.sort(sortArrayByDeliveryDate);

	const newOrdersArray = sortedOrdersArray?.map(thisOrder => {
		const accQty = thisOrder?.orderDetails?.reduce((sum, item) => ({
			quantity: sum?.quantity + item?.quantity
		}));
		const { productDetail, name } = thisOrder?.orderDetails[0];
		return {
			...productDetail,
			name,
			orderId: thisOrder?.id,
			orderDeliveryDate: thisOrder?.deliveryDate,
			orderReview: thisOrder.review,
			orderQuantity: accQty?.quantity
		};
	});
	return newOrdersArray;
};

export const capitalizeFirstLetter = string => string?.charAt(0)?.toUpperCase() + string?.slice(1);
export const capitalizeFirstLetterFromSentance = sentance => sentance
	?.split(' ')
	?.map(word => word?.[0]?.toUpperCase() + word?.substring(1))
	?.join(' ');

export const getApplicableDiscount = (discounts, deliveryDate) => {
	if (discounts && discounts.length > 0) {
		const validDiscounts = discounts.filter(disc => {
			const {
				startDate, endDate, isActive
			} = disc || {};
			const isValidDiscountDate =
				deliveryDate &&
				startDate &&
				endDate &&
				moment(deliveryDate).isValid() &&
				moment(startDate).isValid() &&
				moment(endDate).isValid() &&
				deliveryDate.isBetween(startDate, endDate, 'day', '[]');
			return isValidDiscountDate && isActive;
		});
		const webDiscounts = validDiscounts.filter(
			disc => (disc.environment === null || disc.environment.indexOf('web') > -1) && disc.priority !== 0
		);
		const noPriorityDiscounts = validDiscounts.filter(
			disc => (disc.environment === null || disc.environment.indexOf('web') > -1) && disc.priority === 0
		);
		if (webDiscounts.length > 0) {
			const sortedPriorityDiscounts = webDiscounts.sort((a, b) => {
				if (a.priority >= b.priority) {
					return 1;
				}
				return -1;
			});
			return sortedPriorityDiscounts[0];
		} if (noPriorityDiscounts.length > 0) {
			const onetimeDiscs = noPriorityDiscounts.filter(d => d.type === 'onetime');
			if (onetimeDiscs.length > 0) {
				return onetimeDiscs[0];
			}
			return noPriorityDiscounts[0];
		}
		return webDiscounts;
	}
	return discounts;
};

export const TIME_IN_GERMANY = moment(moment.tz('Europe/Berlin').format().split('+')[0]);
