import { USER_TOKEN } from '../../constants/authConstants';
import { clearLocalStorage, setLocalStorage } from '../../utils/localStorage';
import apiHelper from '../apiHelper';

const handleErrorMsg = err => {
	const message = err?.error?.data?.message;
	return {
		error: {
			emailErr: message,
			passwordErr: message
		}
	};
};

export const getLocalisation = async () => {
	try {
		const url = 'pub/localization';
		const response = await apiHelper.get(url);
		return response?.result?.i18n;
	} catch (err) {
		return handleErrorMsg(err);
	}
};

export const userSignIn = async data => {
	try {
		const url = 'auth/signin/web/null/null';
		const response = await apiHelper.post(url, { requestBody: data });
		if (response?.result) {
			setLocalStorage(USER_TOKEN, response.result?.token);
		}
		return response;
	} catch (err) {
		return handleErrorMsg(err);
	}
};
export const userSignUp = async data => {
	try {
		const response = await apiHelper.put('auth/signup', { requestBody: data });
		if (response?.result) {
			setLocalStorage(USER_TOKEN, response?.result?.token);
		}
		return response;
	} catch (err) {
		return handleErrorMsg(err);
	}
};
export const userVerify = async ({ email }) => {
	try {
		const response = await apiHelper.get(`auth/verify/${email}`);
		return response?.result;
	} catch (err) {
		return err?.error?.data;
	}
};

export const validateToken = async token => {
	try {
		const response = await apiHelper.get(`auth/validate/${token}`);
		return response?.result;
	} catch (err) {
		return err?.error?.data;
	}
};

export const confirmEmailAdress = async ({ token }) => {
	try {
		const response = await apiHelper.get(`auth/confirm/${token}`);
		return response?.result;
	} catch (err) {
		return err?.error?.data;
	}
};
export const retryEmailVerification = async ({ email }) => {
	try {
		const response = await apiHelper.get(`auth/confirm/retry/${email}`);
		return response;
	} catch (err) {
		return err?.error?.data;
	}
};

export const forgotPassword = async email => {
	try {
		const response = await apiHelper.get(`auth/forgot/password/${email}`);
		return response;
	} catch (err) {
		return err?.error?.data;
	}
};

export const logOut = async history => {
	await apiHelper.get('auth/signout');
	const { status } = clearLocalStorage(USER_TOKEN);
	if (status) {
		history.push('/login');
	}
};
