export const PAYMENT_TYPE_A = 'payroll';
export const PAYMENT_TYPE_B = 'creditcard/wallet';

export const PAYMENT_SETTING_CARD = 'card';
export const PAYMENT_SETTING_WALLET = 'wallet';

export const ORDER_STATUS_ORDERED = 'ordered';
export const ORDER_STATUS_PREPARED = 'prepared';
export const ORDER_STATUS_OUT_FOR_DELIVERY = 'out for delivery';
export const ORDER_STATUS_DELIVERED = 'delivered';
export const ORDER_STATUS_CANCELLED = 'cancelled';

export const CC_TYPE_A = 'CCTypeA';
export const CARD_CONNECT_SUCCESS = 'CARD_CONNECT_SUCCESS';
export const CARD_CONNECT_ERROR = 'CARD_CONNECT_ERROR';
export const GET_INFO_ERROR = 'GET_INFO_ERROR';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const CANCEL_ORDER_MESSAGE =
  'Are you sure you want to cancel your previous order and order this dish.';
export const OUTOF_STOCK_MESSAGE = 'Ordering quantity exceeds in-stock quantity!';
export const CUTOFFTIME_ORDER_MESSAGE = `
	You've missed the ordering cut-off for today. 
	But we got you covered - we've moved you to the next available slot - just choose a dish and click the buy button!
`;

export const DISCOUNT_TYPE_PERCENTAGE = 'percentage';
export const DISCOUNT_TYPE_FIXED = 'fixed';
export const DISCOUNT_TYPE_ONETIME = 'onetime';

export const EMPTY_ACTIVEDAY_MENU_MESSAGE = {
	title: 'Looks like the menu for this day can’t be displayed.',
	text: 'Try a different day (or month). We’ll be back with new delights soon!'
	// title: 'We\'re closed till the 6th of June!',
	// text: 'We look forward to enchant you again with a burst of summer delights!',
	// showLink: true,
	// linkTo: '/orders/07-06-2021'
	// title:
	// 'We will reopen on the 12th of April with a lot of new features and highlights for you!',
	// text: 'Stay tuned!'
};

export const EMPTY_FILTERED_DISH_MESSAGE = {
	title: 'Looks like there are no dishes here.',
	text: 'Try adjusting your filters or try a different month.'
};
export const EMPTY_PAST_ORDER_MESSAGE = {
	title: 'Looks like you have no past orders',
	text: 'You can order healthy dishes',
	showLink: true
};

export const popupSequence = {
	ordersPage: {
		NPS_REVIEW: 'NPS_REVIEW',
		ORDER_REVIEW: 'ORDER_REVIEW'
	}
};

export const WEB_IMAGE_PLACEHOLDER =
  'https://bellabona-e-commerce.s3.eu-central-1.amazonaws.com/UtilityImages/picture+coming+soon+1200.jpeg';

export const MOBILE_IMAGE_PLACEHOLDER =
  'https://bellabona-e-commerce.s3.eu-central-1.amazonaws.com/UtilityImages/picture+coming+soon+800.jpeg';

export const ADDONS_CAT = 'addons';
