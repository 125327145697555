import React from 'react';
import { Steps } from 'intro.js-react';
import { useTranslation } from 'react-i18next';
import style from './style.scss';

const Intro = ({ steps, onSteps, options }) => {
	const { t } = useTranslation();
	const headerSteps = [
		{
			element: '.user_wallet',
			intro: t('Introducing the Bella & Bona Wallet!'),
			position: 'left',
			tooltipClass: `${style.intro_tooltip_class}`
		}
	];
	const profileSteps = [
		{
			element: '.payment_method',
			intro: t(
				'You can use the wallet or use the credit card as your payment method. Use the wallet to get bonuses and perks.'
			),
			position: 'right',
			tooltipClass: `${style.intro_tooltip_class}`
		},
		{
			element: '.credit_types',
			intro: t(
				'Use the credit options to load money onto your wallet and get some extra credits, on us.'
			),
			position: 'right',
			tooltipClass: `${style.intro_tooltip_class}`
		}
	];
	return (
		<Steps
			enabled
			steps={steps === 0 ? headerSteps : profileSteps}
			initialStep={0}
			onExit={onSteps}
			onComplete={onSteps}
			options={options}
		/>
	);
};

export default Intro;
