import firebase from '@firebase/app';
import '@firebase/messaging';
import 'firebase/analytics';
import { firebaseConfig } from '../constants/firebaseConfig';

// Workaroudn to pass unit test
let messaging;
if (firebaseConfig.projectId) {
	firebase.initializeApp(firebaseConfig);
	if (firebase.messaging.isSupported()) {
		messaging = firebase.messaging();
		messaging.usePublicVapidKey('Key');
	}
	// const analytics = firebase.analytics();
	firebase.analytics();
}

export const requestFirebaseNotificationPermission = () => new Promise((resolve, reject) => {
	const getTocken = () => {
		messaging
			?.getToken({ vapidKey: process.env.FIREBASE_PUBLIC_VAPID_KEY })
			.then(firebaseToken => {
				// console.warn('firebaseToken', firebaseToken);
				resolve(firebaseToken);
			})
			.catch(err => {
				// console.warn('firebaseToken err', err);
				reject(err);
			});
	};
	if (Notification.permission === 'granted') {
		getTocken();
	} else if (Notification.permission !== 'denied') {
		Notification.requestPermission(res => {
			// console.log(res);
			if (res === 'granted') {
				getTocken();
			} else {
				reject(res);
			}
		});
	} else {
		reject(Notification.permission);
	}
});

export const onMessageListener = () => new Promise(resolve => {
	messaging.onMessage(payload => {
		// console.log('payload', payload);
		resolve(payload);
	});
});
