import { useEffect } from 'react';

const useOutsideClick = (ref, cb) => {
	useEffect(() => {
		const handleClickOutside = event => {
			const rootEl = document.getElementById('root');
			if (
				ref.current && 
				!ref.current.contains(event.target) && 
				rootEl?.contains(ref.current) && 
				rootEl?.contains(event.target)
			) {
				cb();
			}
		};
		document.addEventListener('click', handleClickOutside);
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
};
export default useOutsideClick;
