import axios from 'axios';
import { USER_TOKEN } from '../constants/authConstants';
import { clearLocalStorage } from '../utils/localStorage';
import { SERVER_ERROR } from '../constants/common';

const { CancelToken } = axios;
const { API_ENDPOINT } = process.env;

const serverResponseHandler = response => {
	switch (response?.status) {
	case 200:
		// authCredentials = response.data?.data?.token;
		break;
	case 400:
		break;
	case 401:
		clearLocalStorage(USER_TOKEN);
		if (window.location.pathname !== '/login') {
			window.location.replace('/login');
		}
		break;
	default:
	}
};

const clientRequest = (url, options) => new Promise((resolve, reject) => {
	const source = new CancelToken.source();
	options.cancelToken = source.token;
	options.baseURL = API_ENDPOINT;

	const request = axios.create({ withCredentials: true });

	request(url, options)
		.then(response => {
			serverResponseHandler(response);
			resolve({
				result: response.data?.data,
				error: null
			});
		})
		.catch(err => {
			serverResponseHandler(err?.response);
			let error = err?.response?.data || {
				data: { message: SERVER_ERROR },
				status: false
			};
			error = { error, result: null, code: err?.response?.status };
			reject(error);
		});
});

export default {
	get: (url, options) => {
		const requestOptions = {
			method: 'GET',
			...options
		};

		return clientRequest(url, requestOptions);
	},
	post: (url, options) => {
		const requestOptions = {
			method: 'POST',
			data: options.requestBody
		};

		return clientRequest(url, requestOptions);
	},
	put: (url, options) => {
		const requestOptions = {
			...options,
			method: 'PUT',
			data: options.requestBody
		};

		return clientRequest(url, requestOptions);
	},
	patch: (url, options) => {
		const requestOptions = {
			method: 'PATCH',
			...options
		};

		return clientRequest(url, requestOptions);
	},
	delete: (url, options) => {
		const requestOptions = {
			method: 'DELETE',
			...options
		};

		return clientRequest(url, requestOptions);
	}
};
