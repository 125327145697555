import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './date-selector.scss';
import { GENERIC_STICKER_THUMBNAIL } from '../../../constants/urlConfig';

const DateCard = ({
	dateData,
	isBlackedOut,
	isDateSelected,
	onClickHandler,
	sticker
}) => {
	const { t } = useTranslation();
	return (
		<div className={styles.date_button_wrapper}>
			<button
				type='button'
				className={`${styles.date_button} ${
					isDateSelected ? styles.date_selected : ''
				}`}
				onClick={() => onClickHandler(`${dateData?.date} ${dateData?.day}`)}
				onKeyUp={() => {}}
				tabIndex={0}
				disabled={isBlackedOut}
			>
				{sticker?.url && (
					<figure className={styles.lid_graphics}>
						<img
							src={sticker.url}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null;	// prevents looping							
								currentTarget.src = GENERIC_STICKER_THUMBNAIL;
							}}
							alt='Bella&Bona'
						/>

						{sticker?.quantity > 1 && (
							<span className={styles.order_quantity}>{sticker.quantity}</span>
						)}
					</figure>
				)}
				<div className={styles.date_outer}>
					<span className={styles.date_value}>{dateData?.date}</span>
					{t(dateData?.day)}
				</div>
			</button>
		</div>
	);
};

DateCard.defaultProps = {
	isBlackedOut: false,
	isDateSelected: false,
	sticker: {
		url: '',
		count: 0
	}
};

DateCard.propTypes = {
	dateData: PropTypes.shape({
		date: PropTypes.string.isRequired,
		day: PropTypes.string.isRequired
	}).isRequired,
	sticker: PropTypes.shape({
		url: PropTypes.string,
		count: PropTypes.number
	}),
	isBlackedOut: PropTypes.bool,
	isDateSelected: PropTypes.bool,
	onClickHandler: PropTypes.func.isRequired
};

export default memo(DateCard);
