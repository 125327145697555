import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useOutsideClick from './hooks';

const ClickOutside = ({ 
	children, 
	callbackHandler, 
	wrapperRef, 
	...rest 
}) => {
	const defaultRef = useRef(null);

	useOutsideClick(wrapperRef || defaultRef, callbackHandler, children);
	return (
		<div ref={defaultRef} {...rest}>
			{children}
		</div>
	);
};

ClickOutside.propTypes = {
	children: PropTypes.element.isRequired,
	callbackHandler: PropTypes.func.isRequired
};
export default ClickOutside;
