import React, { useEffect, useState } from 'react';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import BottomDrawer from '../common/BottomDrawer';
import Button from '../common/Button';
import style from './appRedirections.scss';
import logo from '../../assets/images/icons/icona.svg';
import { APP_STORE_LINK, PLAY_STORE_LINK } from '../../constants/urlConfig';

const AppRedirections = () => {
	const [appModal, setAppModal] = useState(false);

	const { t } = useTranslation();

	useEffect(() => {
		if (isMobile) {
			setAppModal(true);
		}
	}, []);

	return (
		<BottomDrawer
			modalId='deepLinking'
			onCloseBottomDrawer={() => {
				setAppModal();
			}}
			isShown={appModal}
		>
			<div className={style.wrapper}>
				<div className={style.header}>
					<b>{t('See Bella & Bona in...')}</b>
					<hr className={style.divider} />
				</div>

				<div className={style.option}>
					<div className={style.option_app}>
						<img src={logo} className={style.logo} alt='Bella & Bona' />
						<div className={style.option_label}>
							{t('Bella & Bona App')}
						</div>
					</div>
					<a
						href={
							isIOS ? APP_STORE_LINK :
								isAndroid ? PLAY_STORE_LINK : null
						}
						target='_blank'
						rel='noreferrer'
						className={style.button}
					>
						{t('Open')}
					</a>
				</div>

				<Button
					onClickHandler={() => setAppModal(false)}
					size='small'
					variant='text'
				>
					{t('Continue with Browser')}
				</Button>
			</div>
		</BottomDrawer>
	);
};

export default AppRedirections;
