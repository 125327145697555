import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import axios from 'axios';
import { LOCLIZATION_STRAPI } from '../constants/common';

const resources = {};
// eslint-disable-next-line func-names
(async function () {
	const localisation = await axios.get(LOCLIZATION_STRAPI);
	localisation?.data?.i18n?.forEach(o => {
		Object.keys(o).forEach(key => {
			let langCode;
			let langKey;
			let langKeyVal;
			if (key && (key === 'en' || key === 'de')) {
				langCode = key === 'en' ? 'en' : 'de';
				langKey = o.en;
				langKeyVal = o[`${key}`];
				if (!resources[`${langCode}`]) {
					resources[`${langCode}`] = {
						translation: {}
					};
				}
				resources[`${langCode}`].translation[`${langKey}`] = langKeyVal;
			}
		});
	});
}());

const lang = localStorage.getItem('LANG');

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: lang || (navigator.language.indexOf('en') > -1 ? 'en' : 'de'), // Default langauge
		keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;
