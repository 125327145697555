export const USER_TOKEN = 'userToken';

export const MESSAGE_PROPS = {
	message: `The team at Bella&Bona have been an amazing support. 
	Having this great variety of dishes on offer for our members on a daily basis, is simply amazing. 
	It‘s almost impossible to get bored.`,
	name: 'Michael Müller',
	isVerified: true
};

export const EMAIL_RESET_PATH = '/resetpassword/';
export const RESET_TOKEN = 'RESET_TOKEN';
export const PASSWORD_CHANGE_SUCCESS = 'Password changed successfully!';
export const CURRENT_PASSWORD_ERROR = 'Current Password entered is incorrect. Please try again.';
