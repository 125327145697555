import React from 'react';
import { withTranslation } from 'react-i18next';
import style from './error-page.scss';
import logo from '../../assets/images/bellabona-logo.svg';

const handleRefresh = () => {
	window?.location?.reload();
};

// this is the only class based component
// because function components don't yet have a componentdidcatch implementation
class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch() {
		this.setState({ hasError: true });
	}

	render() {
		const { t } = this.props;
		const { hasError } = this.state;
		if (hasError) {
			return (
				<div className={style.error_container}>
					<div className={`${style.logo}`}>
						<img src={logo} alt='Bella & Bona' />
					</div>
					<div className={style.content}>
						<div className={style.section}>
							<span className={style.alert_icon_bg}>
								<i className='icon-alert_circle' />
							</span>
							<span className={style.big_text}>{t('Oops!')}</span>
						</div>
						<p className={`${style.text} ${style.bold}`}>
							{t('Something is not right...')}
						</p>
						<p className={style.text}>
							{t('We are working on fixing the problem')}
						</p>
						<p className={style.text}>{t('Please try again')}</p>
						<div
							className={style.refresh_section}
							role='button'
							onClick={handleRefresh}
							onKeyDown={() => {}}
							tabIndex={0}
						>
							<span className={style.refresh_icon}>
								<i className='icon-refresh' />
							</span>
							<p className={style.text}>{t('Refresh Page')}</p>
						</div>
					</div>
				</div>
			);
		}
		const { children } = this.props;
		return children;
	}
}

export default withTranslation()(ErrorBoundary);
