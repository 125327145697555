import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment-timezone';
import { useRouteMatch, withRouter, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DateSelector from '../../common/DateSelector';
import UserInfoContext from '../../../providers/userInfoContext';
import OrderWeekDataContext from '../../../providers/orderWeekDataContext';
import { getOrders } from '../../../api/orders';
import { getStartAndEndOfDay, filterOrdersByDate } from '../../../utils';
import { useSetMenuData } from '../../../hooks/orderHooks';

if (!process.env.API_ENDPOINT.includes('localhost')) {
	moment.tz.setDefault('Europe/Berlin');
}
const DateSelectorHOC = ({ children }) => {
	const { userInfo } = useContext(UserInfoContext);
	const { t } = useTranslation();
	const location = useLocation();
	const [gettingData, setGettingData] = useState(false);
	const [weekDayArray, setWeekDayArray] = useState(false);
	const [deliveryDate, setDeliveryDate] = useState(null);
	const [statusMessage, setStatusMessage] = useState(null);
	const [selectedWeekOrders, setSelectedWeekOrders] = useState([]);
	const [selectedDayOrders, setSelectedDayOrders] = useState([]);
	const [aDM, updateMenu, isMenuLoading] = useSetMenuData(deliveryDate);

	const isInPastOrder = useRouteMatch({ path: '/past-orders' })?.isExact || false;

	const onDaySelected = dateData => {
		const { dateObject } = dateData || {};
		setDeliveryDate(dateObject?.startOf('day').add(12, 'hours'));
	};
	const dateValidationHelper = () => {
		const { dateObject: weekStartDate } = weekDayArray?.[0] || {};
		const { dateObject: weekEndDate } = weekDayArray?.[weekDayArray.length - 1] || {};

		const isValidStartDate = weekStartDate && moment(weekStartDate).isValid();
		const isValidEndDate = weekEndDate && moment(weekEndDate).isValid();
		const isSelectedWeekDate = deliveryDate && moment(deliveryDate).isBetween(weekStartDate, weekEndDate, null, '[]');
		return {
			isValidStartDate,
			isValidEndDate,
			isSelectedWeekDate,
			weekStartDate,
			weekEndDate
		};
	};
	const getOrdersForTheWeek = async () => {
		setGettingData(true);
		const {
			isValidStartDate,
			isValidEndDate,
			weekStartDate,
			weekEndDate
		} = dateValidationHelper();
		if (isValidStartDate && isValidEndDate && userInfo) {
			const { startDateUnix, endDateUnix } = getStartAndEndOfDay(weekStartDate, weekEndDate);
			await getOrders(startDateUnix, endDateUnix, userInfo?.id, {
				setStatusMessage,
				setOrdersData: setSelectedWeekOrders,
				t
			});
		}
		setGettingData(false);
	};
	const getOrdersForTheDate = async () => {
		setGettingData(true);
		const { isValidStartDate, isValidEndDate } = dateValidationHelper();
		if (isValidStartDate && isValidEndDate && userInfo && deliveryDate) {
			const { startDateUnix, endDateUnix } = getStartAndEndOfDay(deliveryDate);
			await getOrders(startDateUnix, endDateUnix, userInfo?.id, {
				setStatusMessage,
				setOrdersData: setSelectedDayOrders,
				t
			});
		}
		setGettingData(false);
	};

	const getSelectedDayOrders = async () => {
		const { isSelectedWeekDate } = dateValidationHelper();
		if (isSelectedWeekDate) {
			await getOrdersForTheWeek();
		} else {
			await getOrdersForTheDate();
		}
	};

	const onWindowFocus = async () => {
		await getSelectedDayOrders();
	};

	useEffect(() => {
		(async () => {
			if (weekDayArray?.length) {
				await getOrdersForTheWeek();
				window.addEventListener('focus', await onWindowFocus);
			}
			window.removeEventListener('blur', await onWindowFocus);
		})();
		return () => {
			(async () => window.removeEventListener('focus', await onWindowFocus))();
		};
	}, [weekDayArray]);

	useEffect(() => {
		if (selectedWeekOrders && deliveryDate && moment(deliveryDate).isValid()) {
			const { isSelectedWeekDate } = dateValidationHelper();
			if (!isSelectedWeekDate && !isInPastOrder) {
				getOrdersForTheDate();
			} else {
				const orderForTheDay = filterOrdersByDate(selectedWeekOrders, deliveryDate);
				setSelectedDayOrders(orderForTheDay);
			}
		}
	}, [selectedWeekOrders, deliveryDate]);

	useEffect(() => {
		if (location.pathname === '/orders' || location.pathname === '/past-orders') {
			setDeliveryDate(null);
		}
	}, [location]);

	return (
		<>
			<DateSelector
				onClickDay={onDaySelected}
				loading={gettingData}
				orderData={selectedWeekOrders}
				onChangeWeek={setWeekDayArray}
				isInPastOrder={isInPastOrder}
				aDM={aDM}
			/>
			<OrderWeekDataContext.Provider value={
				{
					deliveryDate,
					weekDayArray,
					selectedDayOrders,
					getSelectedDayOrders,
					statusMessage,
					isLoadingOrders: gettingData,
					aDM,
					updateMenu,
					isMenuLoading
				}
			}
			>
				{children}
			</OrderWeekDataContext.Provider>
		</>
	);
};

export default withRouter(DateSelectorHOC);
