import React, { memo } from 'react';
import PropTypes from 'prop-types';
import style from './tooltip.scss';

const Tooltip = ({
	position,
	label,
	children,
	className,
	width = 'auto'
}) => (
	<div className={`${style.tooltip_wrapper} ${className}`}>
		{children}
		<span 
			className={`tooltip_label ${style.tooltip} ${style[String(position)]}`} 
			style={{ width }}
		>
			{label}
		</span>
	</div>
);
Tooltip.displayName = 'Tooltip';
Tooltip.defaultProps = {
	position: 'bottom',
	className: ''
};

Tooltip.propTypes = {
	position: PropTypes.oneOf(['bottom', 'top', 'left', 'right']),
	label: PropTypes.string.isRequired,
	className: PropTypes.string
};

export default memo(Tooltip);
