import apiHelper from '../apiHelper';
import { ORDER_STATUS_CANCELLED } from '../../constants/orderConstants';
import { ORDER_SUCCESS } from '../../constants/messageContants';

const requestOptions = {
	// crossDomain: true
};

export const createOrdersAPI = async requestBody => {
	const createOrdersUrl = 'orders';
	try {
		const response = await apiHelper.post(createOrdersUrl, {
			...requestOptions,
			requestBody
		});
		if (response?.result) {
			return response;
		}
		throw response;
	} catch (err) {
		return err;
	}
};

export const createStagedOrdersAPI = async requestBody => {
	const StagedOrderUrl = 'stage/orders';
	try {
		const response = await apiHelper.post(StagedOrderUrl, {
			requestBody
		});
		if (response?.result) {
			return response;
		}
		throw response;
	} catch (err) {
		return err;
	}
};

export const getOrders = async (startDate, endDate, customerId, initCallBacks) => {
	const orderFilterBaseUrl = 'orders/customer/filter/date';
	let orderUrl;

	if (startDate && endDate) {
		orderUrl = `${orderFilterBaseUrl}/${startDate}/${endDate}/${customerId}`;
	} else if (endDate) {
		orderUrl = `orders/filter/date/${endDate}`;
	}

	try {
		const response = await apiHelper.get(orderUrl, requestOptions);
		const result = response?.result;
		let activeOrders = [];
		if (result) {
			activeOrders = result
				.filter(item => item.status !== ORDER_STATUS_CANCELLED)
				.map(ele => {
					ele.choosen = false;
					return ele;
				});
			initCallBacks?.setOrdersData?.(activeOrders);
			return activeOrders;
		}
		return activeOrders;
	} catch (err) {
		initCallBacks?.setStatusMessage?.({
			message: initCallBacks?.t?.(err?.error?.data?.message),
			messageType: 'error'
		});
		return null;
	}
};

export const cancelOrder = async (orderId, requestBody, initCallBacks) => {
	const cancelOrderUrl = `orders/${orderId}`;
	// initCallBacks.setGettingData(true);
	try {
		const response = await apiHelper.put(cancelOrderUrl, { requestBody });
		if (response?.result) {
			initCallBacks?.setStatusMessage?.({
				message: initCallBacks?.t?.(ORDER_SUCCESS),
				messageType: 'success'
			});
		}
		// initCallBacks.setGettingData(false);
	} catch (err) {
		// initCallBacks.setGettingData(false);
		initCallBacks?.setStatusMessage?.({
			message: initCallBacks?.t?.(err?.error?.data?.message),
			messageType: 'error'
		});
	}
};

export const getOrderRating = async orderId => {
	const getRatingUrl = `orders/feedback/:${orderId}`;

	try {
		const response = await apiHelper.get(getRatingUrl);
		if (response?.error) {
			return response.error;
		}

		return response?.result;
	} catch (err) {
		return err;
	}
};

export const rateOrder = async (orderId, orderRatings) => {
	const rateDishUrl = `orders/feedback/${orderId}`;

	const requestBody = orderRatings;

	try {
		const response = await apiHelper.post(rateDishUrl, { requestBody });

		if (response?.error) {
			return response.error;
		}

		return response?.result;
	} catch (err) {
		return err;
	}
};

export const updateRateOrder = async (orderId, orderRatings) => {
	const rateDishUrl = `orders/feedback/update/${orderId}`;

	const requestBody = orderRatings;

	try {
		const response = await apiHelper.put(rateDishUrl, { requestBody });

		if (response?.error) {
			return response.error;
		}

		return response?.result;
	} catch (err) {
		return err;
	}
};

export const unRateOrder = async orderId => {
	const deleteDishRatingUrl = `orders/feedback/delete/${orderId}`;

	try {
		const response = await apiHelper.delete(deleteDishRatingUrl);
		if (response?.error) {
			return response.error;
		}

		return response?.result;
	} catch (err) {
		return err;
	}
};

export const payByWallet = async orderId => {
	const payByWalletUrl = `orders/payment/update/wallet/${orderId}`;
	try {
		const response = await apiHelper.put(payByWalletUrl, {});
		return response?.result;
	} catch (err) {
		return err;
	}
};

export const getAllAllergens = async () => {
	const getAllergensURL = 'admin/allergen/user';

	try {
		const response = await apiHelper.get(getAllergensURL);
		if (response?.error) {
			return response.error;
		}

		return response?.result;
	} catch (err) {
		return err;
	}
};
