import { useEffect, useState } from 'react';
import { getMenuDishesViaBranch } from '../api/menu';
import { ADDONS_CAT } from '../constants/orderConstants';
import {
	getPayablePrice,
	isCurrentMonthMenu,
	toFixedNumber
} from '../utils/orders/orderHelper';
import { YYYY_MM_DD } from '../constants/dayDateConstants';

export const usePayablePrice = (
	initialPrice,
	allowanceInfo = {
		isFirstOrder: true,
		allowance: 0
	},
	quantity,
	discountInfo,
	deliveryDate,
	category,
	applyAllowanceToAddons
) => {
	const [dishPrice, setDishPrice] = useState({});

	useEffect(() => {
		const { displayPrice, totalPayablePrice } = getPayablePrice({
			initialPrice,
			allowanceInfo,
			quantity,
			discountInfo: category === ADDONS_CAT ? null : discountInfo,
			deliveryDate,
			applyAllowance: category === ADDONS_CAT ? applyAllowanceToAddons : true
		});

		setDishPrice({
			displayPrice: toFixedNumber(displayPrice),
			totalPayablePrice: toFixedNumber(totalPayablePrice)
		});
	}, [initialPrice, allowanceInfo, quantity, discountInfo, deliveryDate, category]);

	return dishPrice;
};

export const useSetMenuData = deliveryDate => {
	const [menuData, setMenuData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMessage] = useState({});

	const setCurrentMenu = (data, selectedDate) => {
		const activeMenuData = 
			data?.filter(item => isCurrentMonthMenu(selectedDate, item.startDate, item.endDate))?.[0];
		setMenuData(activeMenuData?.dishes || []);
	};

	const fetchMenuData = async deliveryDateObject => {
		setIsLoading(true);
		const dateSelected = deliveryDateObject?.format(YYYY_MM_DD) ?? '';
		await getMenuDishesViaBranch(dateSelected)
			.then(response => {
				setCurrentMenu(response, deliveryDateObject);
			})
			.catch(err => {
				setErrorMessage(err);
				setMenuData([]);
			});
		setIsLoading(false);
	};

	useEffect(() => {
		if (deliveryDate) {
			fetchMenuData(deliveryDate);
		}
	}, [deliveryDate]);

	return [menuData, fetchMenuData, isLoading, errorMsg];
};
