import React, {
	createContext,
	useContext,
	useEffect,
	useState
} from 'react';
import apiHelper from '../api/apiHelper';

async function getFeatureFlags() {
	try {
		return (await apiHelper.get('/feature-flags')).result;
	} catch {
		return [];
	}
}

const FeatureFlagsContext = createContext();

export function useFeatureFlags() {
	return useContext(FeatureFlagsContext);
}

export function FeatureFlags({ children }) {
	const [featureFlags, setFeatureFlags] = useState(null);
	
	useEffect(() => {
		getFeatureFlags().then(setFeatureFlags);
	}, []);

	return (
		<FeatureFlagsContext.Provider value={featureFlags}>
			{children}
		</FeatureFlagsContext.Provider>
	);
}
