import apiHelper from '../apiHelper';
// const requestOptions = {
// 	crossDomain: true
// };

export const getMenuDishesViaBranch = async deliveryDate => {
	const getMenuUrl = `menu/branch/all/${deliveryDate}`;
	try {
		const response = await apiHelper.get(getMenuUrl);

		if (response?.result?.length) {
			const data = response?.result;
			return data;
		}

		return null;
	} catch (err) {
		return { message: err?.error?.data?.message, messageType: 'error' };
	}
};

export const getMenuDishesViaBranchForView = async id => {
	const getMenuUrl = 'pub/menu';
	try {
		const response = await apiHelper.get(`${getMenuUrl}/${id}?type=shared`);
						
		if (response?.result?.length) {
			const data = response?.result;
			return data;
		}
							
		return null;
	} catch (err) {
		return { message: err?.error?.data?.message, messageType: 'error' };
	}
};

export const getCateringMenuDishesViaBranchForView = async id => {
	const getMenuUrl = 'pub/menu';
	try {
		const response = await apiHelper.get(`${getMenuUrl}/${id}`);
						
		if (response?.result?.length) {
			const data = response?.result;
			return data;
		}
							
		return null;
	} catch (err) {
		return { message: err?.error?.data?.message, messageType: 'error' };
	}
};

export const getAvaiableKitchens = async () => {
	const getKitchens = 'pub/kitchens';
	try {
		const response = await apiHelper.get(getKitchens);
						
		if (response?.result?.length) {
			const data = response?.result;
			return data;
		}
							
		return null;
	} catch (err) {
		return { message: err?.error?.data?.message, messageType: 'error' };
	}
};
