import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import ClickOutside from '../ClickOutside';
import style from './bottomDrawer.scss';
import { ESC_KEY } from '../../../constants/common';

const BottomDrawer = ({
	modalId, showClose, children, onCloseBottomDrawer, className, isShown
}) => {
	useEffect(() => {
		const body = document.querySelector('body');
		const header = document.querySelector('header');
		const main = document.querySelector('main');
		if (isShown) {
			if (header && main && body) {
				header.style.paddingRight = `${
					window.innerWidth - document.body?.offsetWidth
				}px`;
				main.style.paddingRight = `${
					window.innerWidth - document.body?.offsetWidth
				}px`;
				body.style.overflow = 'hidden';
			} 
		}

		return () => {
			if (isShown) {
				if (header && main && body) {
					header.style.paddingRight = '0px';
					main.style.paddingRight = '0px';
					body.style.overflow = 'initial';
				} 
			}
		};
	}, [isShown]);

	const changeBottomDrawerState = e => {
		if (e.keyCode === ESC_KEY) {
			onCloseBottomDrawer(false, modalId);
		}
	};

	const closeBottomDrawer = () => {
		onCloseBottomDrawer(false, modalId);
	};

	useEffect(() => {
		document.addEventListener('keydown', changeBottomDrawerState, false);
		return () => {
			document.removeEventListener('keydown', changeBottomDrawerState, false);
		};
	}, []);
	
	return (
		<AnimatePresence>
			{isShown && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					className={`${style.modal_container} modal_wrapper`}
				>
					<motion.div
						initial={{ opacity: 0, transform: `translateY(${160}%)` }}
						animate={{ opacity: 1, transform: `translateY(${0})` }}
						exit={{ opacity: 0, transform: `translateY(${160}%)` }}
					
					>
						<ClickOutside
							callbackHandler={closeBottomDrawer}
							className={`${style.content_wrapper} ${className}`}
						>
							<>
								{showClose && (
									<div
										className={style.close_btn}
										onClick={closeBottomDrawer}
										role='presentation'
									>
										<i className='icon-close' />
									</div>
								)}
								<section
									className={`${style.scroll_container} content_container`}
								>
									<div className={style.scroll_content}>{isShown && children}</div>
								</section>
							</>
						</ClickOutside>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};
BottomDrawer.propTypes = {
	showClose: PropTypes.bool,
	modalId: PropTypes.string.isRequired,
	onCloseBottomDrawer: PropTypes.func.isRequired,
	children: PropTypes.element.isRequired,
	className: PropTypes.string
};
BottomDrawer.defaultProps = {
	showClose: true,
	className: ''
};
export default BottomDrawer;
