import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import TagManager from 'react-gtm-module';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { SENTRY_URL } from './constants/urlConfig';
import './assets/scss/main.scss';
import 'intro.js/introjs.css';
import ErrorBoundary from './layout/Error';
import './i18n';

const { ENABLE_SENTRY } = process.env;
if (ENABLE_SENTRY === '1') {
	Sentry.init({ dsn: SENTRY_URL });
}

// old gtmId: 'GTM-WBVB4C8'; maybe we can regain control over that console?
TagManager.initialize({ gtmId: 'GTM-KCPD3NZD' });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.registerServiceWorker();

ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary>
			<App />
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById('root')
);
