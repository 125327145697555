import React, {
	lazy, Suspense, useState, useEffect
} from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from 'react-router-dom';
import firebase from '@firebase/app';
import PageLayout from './layout/PageLayout';
import { getLocalStorage } from './utils/localStorage';
import { USER_TOKEN } from './constants/authConstants';
import style from './app.scss';
import routes from './routes';
import { requestFirebaseNotificationPermission } from './utils/firebaseInit';
import AppRedirections from './components/AppRedirections';
import { FeatureFlags } from './featureFlags';

const routeComponents = (
	{
		component, name, meta, childRoutes, lazyLoad, ...rest
	},
	firebaseToken
) => {
	const LazyComponent = lazyLoad ? lazy(component) : component;

	if (meta?.withLayout) {
		return (
			<PageLayout key='layout' childRoutes={childRoutes}>
				<Switch>
					{childRoutes?.map(route => routeComponents(route, firebaseToken))}
				</Switch>
			</PageLayout>
		);
	}
	return (
		<Route key={name} {...rest} exact>
			<AuthCheck authRequire={meta?.authRequire}>
				<LazyComponent routes={childRoutes} firebaseToken={firebaseToken} />
			</AuthCheck>
		</Route>
	);
};
const AuthCheck = ({ children, authRequire }) => {
	const { data: token } = getLocalStorage(USER_TOKEN);
	if (authRequire && !token) {
		return <Redirect to='/login' />;
	}
	return <div>{children}</div>;
};

function App() {
	const [firebaseToken, setFirebaseToken] = useState(null);

	useEffect(() => {
		if (firebase.messaging.isSupported()) {
			requestFirebaseNotificationPermission().then(
				token => setFirebaseToken(token),
				() => {
					// This happens for sure in testing, Cypress does not support service workers
					// but `firebase.messaging.isSupported()` returns `true` anyway. Do nothing.
				}
			);
		}
	}, []);

	return (
		<FeatureFlags>
			<Router>
				<Suspense fallback={<div className={style.chunk_loading} />}>
					<Switch>
						{routes?.map(route => routeComponents(route, firebaseToken))}
					</Switch>
				</Suspense>
				<AppRedirections />
			</Router>
		</FeatureFlags>
	);
}

export default App;
