export const WORKING_DAYS = [
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday'
];

export const WEEKENDS = [
	'Sunday'
];

export const START_DATE_OF_WEEK = 'START_DATE_OF_WEEK';
export const DELIVERY_DATE = 'DELIVERY_DATE';
export const DELIVERY_DATE_FORMAT = 'dddd, Do MMM';
export const DELIVERY_TIME_FORMAT = 'HH:mm';
export const YYYY_MM_DD = 'YYYY-MM-DD';
export const DD_MM_YYYY = 'DD/MM/YYYY';
export const PAST_ORDER_DATE_FORMAT = 'Do MMM YYYY';
export const URL_DATE_STRING = 'DD-MM-YYYY';

export const MAX_FUTURE_ORDERS_IN_WEEKS = 3;

// Defailt closing time. No orders after this time (24 hour format)
export const ORDER_PLACEMENT_THRESHOLD_HOUR = 9; 
