import moment from 'moment-timezone';
import { WORKING_DAYS, YYYY_MM_DD } from '../../constants/dayDateConstants';

export const isNotWorkingDay = (day, workingDays) => {
	if (!workingDays || !day) {
		return false;
	}
	if (workingDays.indexOf(day) > -1) {
		return false;
	}
	return true;
};

export const getDaysAndBlackoutArray = (
	firstDay,
	workingDays = WORKING_DAYS,
	blackOutDays = []
) => {
	if (!firstDay?.isValid()) {
		return [];
	}

	const formattedBlackoutDays = [];
	for (let i = 0; i < blackOutDays.length; i += 1) {
		const someDate = moment(blackOutDays[`${i}`], YYYY_MM_DD);
		if (!someDate?.isValid()) {
			return [];
		}
		formattedBlackoutDays.push(someDate.format(YYYY_MM_DD));
	}

	const weekArray = [];

	for (let i = 0; i < 6; i += 1) {
		const thisDateAndDay = firstDay.clone().add(i + 1, 'days');
		const dayName = thisDateAndDay.format('dddd');
		if (workingDays.includes(dayName)) {	
			const perDay = {
				date: thisDateAndDay.format('DD'),
				day: dayName,
				dateObject: thisDateAndDay,
				blackout: (formattedBlackoutDays.includes(thisDateAndDay.format(YYYY_MM_DD)) ||
				moment().subtract(1, 'days').isAfter(thisDateAndDay)) || isNotWorkingDay(dayName, workingDays)
			};

			weekArray.push(perDay);
		}
	}

	return weekArray;
};

export const isDayBlackedOutOrWeekend = (anyDate, blackoutDays = [], weekDays = WORKING_DAYS) => {
	if (anyDate && moment(anyDate, true).isValid()) {
		const day = anyDate.format('dddd');
		for (let i = 0; i < blackoutDays?.length; i += 1) {
			if (anyDate.format(YYYY_MM_DD) === blackoutDays[`${i}`]) {
				return true;
			}
		}

		for (let j = 0; j < weekDays?.length; j += 1) {
			if (day === weekDays[`${j}`]) {
				return false;
			}
		}
		return true;
	}
	return true;
};
