import moment from 'moment-timezone';
import { YYYY_MM_DD } from '../../constants/dayDateConstants';
import {
	PAYMENT_TYPE_A,
	DISCOUNT_TYPE_PERCENTAGE,
	PAYMENT_SETTING_WALLET,
	DISCOUNT_TYPE_ONETIME,
	DISCOUNT_TYPE_FIXED,
	ADDONS_CAT
} from '../../constants/orderConstants';
import { LEFT_ARROW, RIGHT_ARROW } from '../../constants/common';
import { TIME_IN_GERMANY } from '..';
import { checkOrderClosingTime } from '../components/dateDayHelper';

const initialDishDetail = {
	name: null, // Dish name,
	displayNum: null, // Dish varient num value
	productId: null,
	quantity: null,
	price: null, // initialPrice | finalPrice after discount
	fullfillableQuantity: null,
	taxRate: 'none',
	taxValue: 0
};

export const toFixedNumber = value => {
	const fixedNumber = value && !Number.isNaN(Number(value)) ? Number(value.toFixed(2)) : 0.0;
	return fixedNumber;
};

export const getPayablePrice = ({
	initialPrice = 0,
	allowanceInfo = {
		allowance: 0,
		isFirstOrder: true
	},
	quantity = 1,
	discountInfo,
	deliveryDate,
	applyAllowance = true
}) => {
	const {
		startDate, endDate, isActive, type 
	} = discountInfo || {};
	const allowance = applyAllowance ? allowanceInfo?.allowance ?? 0 : 0;
	const isFirstOrder = allowanceInfo?.isFirstOrder ?? false;
	const isValidDiscountDate =
    deliveryDate &&
    startDate &&
    endDate &&
    moment(deliveryDate).isValid() &&
    moment(startDate).isValid() &&
    moment(endDate).isValid() &&
    deliveryDate.isBetween(startDate, endDate, 'day', '[]');
	const discountValue = discountInfo?.value || 0;
	const usedAllowance =
    initialPrice * quantity - allowance < 0 ? initialPrice * quantity : allowance;
	const getDiscountedPrice = () => {
		// const allowancePrice = initialPrice - allowance < 0 ? 0 : initialPrice - allowance;
		const allowancePrice =
      initialPrice * quantity - allowance < 0 ? 0 : initialPrice * quantity - allowance;
		/**
     * Discounts are applied only if the allowance is less than the initial price of the dish
     * this edge case will be taken care after the new subsidy feature is pushed to live
     */
		if (isActive && isValidDiscountDate && isFirstOrder && allowance < initialPrice) {
			const allowancePriceForFirstQty =
        initialPrice * 1 - allowance < 0 ? 0 : initialPrice * 1 - allowance;
			const priceForRestQty = initialPrice * (quantity - 1);
			switch (type) {
			case DISCOUNT_TYPE_PERCENTAGE:
				const discountedPriceForFirstQty = toFixedNumber(
					allowancePriceForFirstQty - allowancePriceForFirstQty * (discountValue / 100)
				);
				return (
					priceForRestQty + discountedPriceForFirstQty
				);

			case DISCOUNT_TYPE_ONETIME:
				return priceForRestQty + discountValue;

			case DISCOUNT_TYPE_FIXED:
				if (allowancePriceForFirstQty - discountValue < 0) {
					return priceForRestQty;
				}
				return priceForRestQty + (allowancePriceForFirstQty - discountValue);

			default:
				return priceForRestQty + allowancePriceForFirstQty;
			}
		}
		return allowancePrice;
	};
	const totalPayablePrice = getDiscountedPrice();

	return { displayPrice: initialPrice, totalPayablePrice, usedAllowance };
};

export const createOrderPayload = ({
	dishData,
	userInfo,
	paymentType,
	discountInfo,
	allowanceInfo = {
		allowance: 0,
		isFirstOrder: true
	},
	quantity = 1,
	deliveryDate: selectedDate,
	applyAllowanceToAddons
}) => {
	if (!moment(selectedDate).isValid() || quantity < 1) {
		return null;
	}
	const deliveryDate = moment(selectedDate).format(YYYY_MM_DD);
	const { id: customerId, defaultPayment } = userInfo;
	const { allowance, isFirstOrder } = allowanceInfo;
	const financialStatus =
    paymentType === PAYMENT_TYPE_A || defaultPayment === PAYMENT_SETTING_WALLET ?
    	'paid' : // eslint-disable-line no-mixed-spaces-and-tabs
    	'unpaid'; // eslint-disable-line no-mixed-spaces-and-tabs
	// displayPrice is used instead of initialPrice for price varient
	const {
		name,
		displayNum,
		displayPrice: initialPrice,
		id: productId,
		category
	} = dishData?.[0] || {};
	let { totalPayablePrice, usedAllowance } = getPayablePrice({
		initialPrice,
		allowanceInfo,
		quantity,
		discountInfo,
		deliveryDate: selectedDate,
		applyAllowance: category === ADDONS_CAT ? applyAllowanceToAddons : true
	});

	totalPayablePrice = toFixedNumber(totalPayablePrice);
	usedAllowance = toFixedNumber(usedAllowance);

	const requestPayload = {
		customerId,
		deliveryDate,
		financialStatus,
		allowance: usedAllowance,
		type: 'DL', // daily lunch
		packaging: userInfo.branches[0].branch.packageOption,
		orderDetails: [],
		/** Send from which branch the order is placed in order to
     * compare the multi branch orders */
		branchId: userInfo?.defaultBranchId
	};
	const dishDetail = {
		...initialDishDetail,
		name,
		displayNum,
		productId,
		price: totalPayablePrice / quantity,
		quantity,
		fullfillableQuantity: quantity
	};
	/**
   * Discounts are applied only if the allowance is less than the initial price of the dish
   * this edge case will be taken care after the new subsidy feature is pushed to live
   */
	if (isFirstOrder && discountInfo?.isActive && allowance < initialPrice) {
		requestPayload.discountId = discountInfo?.id;
	}
	/**
   * Check the allowance and discount applied for non addons.
   * allowance is applied irresepective of the qty and order but discount is applied for the ,
   * first order and first qty if allowance is < initial price of the dish
   */
	if (category !== ADDONS_CAT) {
		const allowanceInfoCpy = { ...allowanceInfo };
		let discountInfoCpy = { ...discountInfo };
		for (let index = 0; index < quantity; index += 1) {
			let {
				displayPrice,
				totalPayablePrice: priceForQty1,
				usedAllowance: usedAllowanceForQty1
			} = getPayablePrice({
				initialPrice,
				allowanceInfo: allowanceInfoCpy,
				quantity: 1,
				discountInfo: discountInfoCpy,
				deliveryDate: selectedDate,
				applyAllowance: true
			});
			displayPrice = toFixedNumber(displayPrice);
			priceForQty1 = toFixedNumber(priceForQty1);
			usedAllowanceForQty1 = toFixedNumber(usedAllowanceForQty1);
			allowanceInfoCpy.allowance = toFixedNumber(
				allowanceInfoCpy.allowance - usedAllowanceForQty1
			);
			discountInfoCpy = null;
			if (priceForQty1 === 0) {
				const i = requestPayload.orderDetails.findIndex(ele => ele.price === 0);
				if (i > -1) {
					requestPayload.orderDetails[`${i}`].quantity += 1;
					requestPayload.orderDetails[`${i}`].fullfillableQuantity += 1;
				} else {
					requestPayload.orderDetails.push({
						...dishDetail,
						price: 0,
						quantity: 1,
						fullfillableQuantity: 1
					});
				}
			} else if (priceForQty1 !== displayPrice) {
				requestPayload.orderDetails.push({
					...dishDetail,
					price: priceForQty1,
					quantity: 1,
					fullfillableQuantity: 1
				});
			} else {
				const i = requestPayload.orderDetails.findIndex(ele => ele.price === priceForQty1);
				if (i > -1) {
					requestPayload.orderDetails[`${i}`].quantity += 1;
					requestPayload.orderDetails[`${i}`].fullfillableQuantity += 1;
				} else {
					requestPayload.orderDetails.push({
						...dishDetail,
						price: priceForQty1,
						quantity: 1,
						fullfillableQuantity: 1
					});
				}
			}
		}
	} else {
		requestPayload.orderDetails.push(dishDetail);
	}
	return requestPayload;
};

export const isOrderedDish = (name, orderData) => {
	const matchingOrder = orderData?.filter(
		({ orderDetails }) => orderDetails?.[0]?.name === name
	);
	return matchingOrder?.length > 0;
};

export const pickOrderedDish = (dishId, orderData) => {
	const matchingOrder = orderData?.filter(
		({ orderDetails }) => orderDetails?.[0]?.productId === dishId
	);
	return matchingOrder;
};
// eslint-disable-next-line max-len
export const sortWithCategoryNum = data => data.sort((a, b) => (a.cat > b.cat ? 1 : a.cat === b.cat ? (a.num > b.num ? 1 : -1) : -1)) ||
  [];

export const checkIfForecastedAndIsToday = (
	branchInfo,
	itemNo,
	stockForToday,
	deliveryDate
) => {
	let forecastedDayDate = moment().clone().utcOffset(0).startOf('day')
		.format('YYYY-MM-DD');

	if (checkOrderClosingTime(branchInfo?.cutOffTime, TIME_IN_GERMANY)) {
		forecastedDayDate = moment()
			.clone()
			.add(1, 'days')
			.utcOffset(0)
			.startOf('day')
			.format('YYYY-MM-DD');
	}
	return (
		typeof stockForToday?.[`${itemNo}`] !== 'undefined' &&
    deliveryDate?.isSame(forecastedDayDate, 'day')
	);
};

export const categoryGroupData = (
	menuData,
	stockForToday,
	branchInfo,
	deliveryDate,
	selectedDayOrders
) => {
	const categorySortOrder = [
		'best_seller',
		'weekly_special',
		'soups_&_desserts',
		'sandwich_&_wraps',
		'cucina_italiana',
		'lunch_classics',
		'salads_&_bowls',
		'out_of_stock'
	];
	const clonedData = menuData?.length ? JSON.parse(JSON.stringify(menuData)) : [];
	const CATEGORY_NAMES = [];
	clonedData.forEach(item => {
		/** Add defualt stock for the the shared menu screen */
		if (!branchInfo && !stockForToday && !deliveryDate && !selectedDayOrders) {
			item.stock = 9999;
		}
		if (CATEGORY_NAMES.indexOf(item?.category) === -1) {
			CATEGORY_NAMES.push(item?.category);
		}
		if (
			!selectedDayOrders?.some(ele => ele?.orderDetails?.[0]?.productId === item?.id) &&
      Object.prototype.hasOwnProperty.call(stockForToday || {}, item.itemNo)
		) {
			item.stock = stockForToday?.[item?.itemNo];
		}
	});

	const topsellingItems = clonedData?.filter(
		// Item should not be displayed there in the best seller
		// if it is tagged with 'weeklyspecial' also.
		item => (item?.stock > 0 ||
        selectedDayOrders?.some(ele => ele?.orderDetails?.[0]?.productId === item?.id)) &&
      item?.tags?.indexOf('bestseller') !== -1 &&
      item?.tags?.indexOf('weeklyspecial') === -1
	);
	const weeklySpecialItems = clonedData?.filter(
		item => (item?.stock > 0 ||
        selectedDayOrders?.some(ele => ele?.orderDetails?.[0]?.productId === item?.id)) &&
      item?.tags?.indexOf('weeklyspecial') !== -1
	);

	// const outOfStockItems = clonedData?.filter(
	// 	item => item?.stock === 0 &&
	//   !selectedDayOrders.some(ele => ele?.orderDetails?.[0]?.productId === item?.id)
	// );

	const categoryData = CATEGORY_NAMES.map(category => {
		const categoryItems = clonedData?.filter(
			item => item?.category === category &&
        item?.tags?.indexOf('bestseller') === -1 &&
        item?.tags?.indexOf('weeklyspecial') === -1 &&
        (item?.stock !== 0 ||
          selectedDayOrders.some(ele => ele?.orderDetails?.[0]?.productId === item?.id))
		);
		return {
			title: category,
			groupId: category?.replace(/\s/g, '_'),
			items: sortWithCategoryNum(categoryItems)
		};
	});
	categoryData.unshift({
		title: 'Weekly Special',
		groupId: 'weekly_special',
		items: sortWithCategoryNum(weeklySpecialItems)
	});
	categoryData.unshift({
		title: 'Best Seller',
		groupId: 'best_seller',
		items: sortWithCategoryNum(topsellingItems)
	});
	// categoryData.push({
	// 	title: 'Out of stock',
	// 	groupId: 'out_of_stock',
	// 	items: sortWithCategoryNum(outOfStockItems)
	// });
	// eslint-disable-next-line max-len
	categoryData.sort((a, b) => (categorySortOrder.indexOf(a.groupId) >= categorySortOrder.indexOf(b.groupId) ? 1 : -1));
	/** Added divId to scroll to particular dish after the
   * success or failure use cases of the payment gateways */
	return categoryData?.map(data => {
		data.items = data?.items?.map((item, index) => ({
			...item,
			divId: `${data?.groupId}_${index}`
		}));
		return data;
	});
};

export const getNextModalItem = (keyCode, currentIndex, data) => {
	if (keyCode === RIGHT_ARROW) {
		if (currentIndex + 1 < data.length) {
			// regular increment of dish item
			return data[currentIndex + 1];
		}
		// if current dish shown in modal is last item in the category
		// set it back to first one
		return data[0];
	}
	if (keyCode === LEFT_ARROW) {
		if (currentIndex - 1 >= 0) {
			// regular decrement of dish item
			return data[currentIndex - 1];
		}
		// if current dish shown in modal is first item in the category
		// set it back to last one
		return data[data.length - 1];
	}
	return data[`${currentIndex}`];
};

export const getAllDishesSorted = sortedDishesCategory => {
	const allItems = [];
	sortedDishesCategory.forEach(dishCatergory => {
		if (dishCatergory.items.length > 0) {
			allItems.push(...dishCatergory.items);
		}
	});
	return allItems;
};

export const isCurrentMonthMenu = (userSelectedDate, startDateString, endDateString) => {
	const start = startDateString ? moment(startDateString, YYYY_MM_DD) : null;
	const end = endDateString ? moment(endDateString, YYYY_MM_DD) : null;

	if (
		userSelectedDate &&
    start &&
    end &&
    start.isValid() &&
    end.isValid() &&
    userSelectedDate.isValid() &&
    userSelectedDate.isBetween(start, end, 'day', '[]')
	) {
		return true;
	}
	return false;
};

export const checkIsOrdersReviewed = (pastOrders, dateToCheckFrom) => {
	const pastDeliveredOrders = pastOrders?.filter(
		order => order?.status === 'delivered' && moment(order?.deliveryDate).isAfter(dateToCheckFrom)
	);

	const unRatedOrders = pastDeliveredOrders?.filter(pastOrder => pastOrder?.review === null);

	return {
		orders: unRatedOrders.slice(0, 3),
		isReviewed: unRatedOrders.length === 0
	};
};

export const checkForPendingReviews = (pastOrdersService, datesService) => {
	// INFO: checkIsOrdersReviewed Returns upto 3 orders
	const unratedOrdersSinceLastWorkingDay = checkIsOrdersReviewed(
		pastOrdersService.pastOrders,
		// dateToCheckTo,
		datesService.dateSinceLastDelivery
	);

	const dateAWeekAgo = moment(datesService.endDateUnix).clone().subtract(1, 'week').valueOf();

	const unratedOrderSinceAWeek = checkIsOrdersReviewed(
		pastOrdersService.pastOrders,
		// dateToCheckTo,
		dateAWeekAgo
	);
	const lastUnratedOrderObj = unratedOrdersSinceLastWorkingDay.orders?.pop();
	const lastUnratedOrder = lastUnratedOrderObj?.orderDetails?.[0];
	if (lastUnratedOrder && lastUnratedOrder.productDetail) {
		lastUnratedOrder.productDetail.orderDeliveryDate = lastUnratedOrderObj?.deliveryDate;
	}

	return { unratedOrderSinceAWeek, lastUnratedOrder };
};
