const { STICKER_IMAGES } = process.env;
export const S3_IMAGE_URL = 'https://bellabona-e-commerce.s3.eu-central-1.amazonaws.com';
export const IMG_URL_TEMPLATE = `${S3_IMAGE_URL}/ProductImages/{{PDI}}/{{MEDIA}}_{{PDI}}.png`;
export const STICKER_IMAGE_URL = `${S3_IMAGE_URL}/${STICKER_IMAGES}/new/800x800/{{NUM}}.jpg`;
export const STICKER_THUMBNAIL_IMAGE_URL = `${S3_IMAGE_URL}/${STICKER_IMAGES}/new/200x200/{{NUM}}.jpg`;
export const GENERIC_STICKER = 
  `${S3_IMAGE_URL}/${STICKER_IMAGES}/new/800x800/generic_sticker.jpg`;
export const GENERIC_STICKER_THUMBNAIL = 
  `${S3_IMAGE_URL}/${STICKER_IMAGES}/new/200x200/generic_sticker.jpg`;
export const SENTRY_URL =
  'https://ef86791f18e8488ab2cf8f3e41667351@o393785.ingest.sentry.io/5385922';

export const APP_STORE_LINK = 'https://apps.apple.com/us/app/bella-bona/id1546362529';
export const PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=com.bellabona.app&hl=zu&gl=US';

export const TEST_API_ENDPOINT = 'https://estaging.bellabona.com/api/';
export const TEST_API_TOKEN =
  'STAGING_USER_TOKEN=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImU1MzA1MWMwLTk2NmEtNDdlZS05MTRmLTVlNzg3ZGE3MGVmZSIsInJvbGUiOiI0YzEyNDU5MS1jZWMwLTExZWEtYTE5Yi1mMzlmZWQ4NmZkMjgiLCJicmFuY2hlcyI6WyJkNTZkNTlmMi1mY2Q3LTQzMzgtOTg5Zi0yZTg2ZjUyYTk0MDQiXSwiaWF0IjoxNjM5NTUyMjg2fQ.rBuSHNVFV-HZ754orGtMeJfcrbSvSX_hGrO3GBbovRQ';
