import React from 'react';
import PropTypes from 'prop-types';
import style from './button.scss';
import dotLoader from '../../../assets/images/dot-loader.svg';

const Button = ({
	onClickHandler, children, variant, size, color, className, disabled, loading
}, { ...rest }) => (
	<button
		type='button'
		className={`${style.button} ${style[`${variant}`]} ${style[`${size}`]} ${style[`${color}`]} ${className}`}
		onClick={e => {
			if (loading) {
				return;
			}
			onClickHandler(e);
		}}
		{...rest}
		disabled={disabled || loading}
	>
		<div className={style.content}>
			{
				loading ? <img className={style.loader} src={dotLoader} alt='loader' /> : children
			}
		</div>
	</button>
);
Button.defaultProps = {
	children: '',
	variant: '',
	size: '',
	color: '',
	loading: false,
	className: '',
	disabled: false
};
Button.propTypes = {
	onClickHandler: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	variant: PropTypes.oneOf(['line', 'text', '']),
	size: PropTypes.oneOf(['small', '']),
	color: PropTypes.oneOf(['secondary', 'danger', '']),
	className: PropTypes.string
};
export default Button;
