import React, {
	useState, useEffect, useContext, memo, useRef 
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserInfoContext from '../../../providers/userInfoContext';
import DateCard from './DateCard';
import styles from './date-selector.scss';
import { getDaysAndBlackoutArray } from '../../../utils/components/blackoutDaysHelper';
import { filterOrdersByDate, getStickerUrl } from '../../../utils';
import {
	MAX_FUTURE_ORDERS_IN_WEEKS,
	WORKING_DAYS,
	URL_DATE_STRING
} from '../../../constants/dayDateConstants';
import Tooltip from '../Tooltip';
import {
	getMomentObject,
	isValidDeliveryDate,
	getNewDeliveryDate,
	checkOrderClosingTime
} from '../../../utils/components/dateDayHelper';

const DateSelector = ({
	onClickBack,
	onClickForward,
	onClickDay,
	onChangeWeek,
	orderData,
	loading,
	isInPastOrder,
	aDM
}) => {
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const isInitialMount = useRef(true);
	const [currentWeekStartDate, setCurrentWeekStartDate] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);
	const { userInfo } = useContext(UserInfoContext);
	const [weekArray, setWeekArray] = useState([]);
	const [pastOrdersRedirect, setPastOrdersRedirect] = useState(false);
	const [backButtonDisabled, setBackButtonDisabled] = useState(false);
	const [forwardButtonDisabled, setForwardButtonDisabled] = useState(false);
	const [workingDaysOfWeek, setWorkingDays] = useState(WORKING_DAYS);
	const [blackoutDays, setBlackoutDays] = useState([]);
	const [showOverlay, setShowOverlay] = useState(true);
	const [maxForwardDate, setMaxForwardDate] = useState(null);

	const routeMatch = path => useRouteMatch({ path });
	const deliveryDate = routeMatch('/orders/:deliveryDate?')?.params
		?.deliveryDate;

	const checkForButtonDisable = () => {
		const startOfThisWeek = getMomentObject(userInfo?.serverTime).startOf(
			'week'
		);
		setPastOrdersRedirect(
			currentWeekStartDate.isSameOrBefore(startOfThisWeek, 'day') &&
        !isInPastOrder
		);
		setForwardButtonDisabled(
			maxForwardDate.startOf('week').isSameOrBefore(currentWeekStartDate)
		);
		setBackButtonDisabled(isInPastOrder);
	};

	const updateWeekArray = startDate => {
		const finalArray = getDaysAndBlackoutArray(
			startDate,
			workingDaysOfWeek,
			blackoutDays
		);
		setWeekArray(finalArray);
		setCurrentWeekStartDate(startDate);
	};

	const onClickPastOrders = () => {
		if (pastOrdersRedirect) {
			history.push('/past-orders'); // If current week, redirect
		} else if (!isInPastOrder) {
			// else, move back one week as usual
			currentWeekStartDate.subtract(1, 'weeks');
			updateWeekArray(currentWeekStartDate);
			onClickBack();
		}
	};

	const onClickFutureOrders = () => {
		if (isInPastOrder) {
			history.push('/orders');
		} else if (!forwardButtonDisabled) {
			currentWeekStartDate.add(1, 'weeks');
			updateWeekArray(currentWeekStartDate);
			onClickForward();
		}
	};

	const onClickSingleDate = dateData => {
		const date = moment(dateData?.dateObject).format(URL_DATE_STRING);
		history.push(`/orders/${date}`);
	};

	const initDateSelection = ({
		startOfWeek,
		date,
		workingDays,
		invalidDays,
		maxDate
	}) => {
		onClickDay({ dateObject: date });
		setSelectedDate(date);
		setCurrentWeekStartDate(startOfWeek);
		setWorkingDays(workingDays);
		setBlackoutDays(invalidDays);
		setMaxForwardDate(maxDate);
	};

	const getStickerData = dateData => {
		let url = null;
		let totalQuanity = null;
		if (!loading && orderData?.length) {
			const orders = filterOrdersByDate(orderData, dateData?.dateObject);
			const dish = aDM?.find(ele => ele?.id === 
				orders?.[0]?.orderDetails?.[0]?.productDetail.id);
			/** displayNum is used as 'num' colulmn varient of dish */
			let { displayNum: num } = dish || {};
			if (!num) {
				num = orders?.[0]?.orderDetails?.[0]?.productDetail?.num || {};
			}
			const { cat } = orders?.[0]?.orderDetails?.[0]?.productDetail || {};
			if (orders?.length) {
				const quantityList = orders?.map(order => order?.orderDetails?.reduce((sum, item) => ({
					quantity: sum?.quantity + item?.quantity
				}), { quantity: 0 }));
				totalQuanity = quantityList?.reduce((total, val) => ({
					quantity: total.quantity + val.quantity
				}), { quantity: 0 });
			}

			url = cat && num ? getStickerUrl(cat, num, true) : null;
		}
		return { url, ...totalQuanity };
	};

	useEffect(() => {
		if (userInfo) {
			const branchInfo = userInfo?.branches?.[0]?.branch;
			const serverTime = getMomentObject(userInfo?.serverTime);
			const currentDate = getNewDeliveryDate(
				serverTime,
				branchInfo?.days,
				branchInfo?.cutOffTime,
				[...branchInfo?.blackoutDates, ...branchInfo?.kitchen?.blackoutDates]
			);
			const startOfWeek = currentDate?.clone().startOf('week')?.startOf('day');
			const validEndDate = startOfWeek
				?.clone()
				.add(MAX_FUTURE_ORDERS_IN_WEEKS, 'week')
				.endOf('week');
			const deliveryDateObj =
				deliveryDate && moment(deliveryDate, URL_DATE_STRING, true);
			let initObj = {
				startOfWeek,
				date: currentDate,
				workingDays: branchInfo?.days,
				invalidDays: [
					...branchInfo?.blackoutDates,
					...branchInfo?.kitchen?.blackoutDates
				],
				maxDate: validEndDate
			};
			const isValidDD = isValidDeliveryDate(
				deliveryDateObj,
				serverTime,
				validEndDate,
				branchInfo?.days,
				[...branchInfo?.blackoutDates, ...branchInfo?.kitchen?.blackoutDates]
			);
			const isClosed = checkOrderClosingTime(
				branchInfo?.cutOffTime,
				userInfo?.serverTime,
				deliveryDateObj
			);
			if (
				deliveryDateObj &&
        ((isValidDD && !isClosed) ||
          (deliveryDateObj.isSame(serverTime, 'day') &&
            history.action !== 'POP'))
			) {
				const startOfSelectedWeek = deliveryDateObj
					?.clone()
					.startOf('week')
					?.startOf('day');
				initObj = {
					...initObj,
					startOfWeek: startOfSelectedWeek,
					date: deliveryDateObj
				};
			} else if (!isInPastOrder) {
				// update the URL with valid date if the current date is invalid
				const urlDate = currentDate.format(URL_DATE_STRING);
				history.push(`/orders/${urlDate}`);
			}

			initDateSelection({
				...initObj
			});
		}
	}, [userInfo, location.pathname]);

	useEffect(() => {
		if (!blackoutDays || !workingDaysOfWeek || !currentWeekStartDate) {
			return;
		}
		updateWeekArray(currentWeekStartDate);
	}, [blackoutDays, workingDaysOfWeek, currentWeekStartDate, selectedDate]);

	useEffect(() => {
		if (!weekArray?.length) {
			return;
		}
		onChangeWeek(weekArray);
		checkForButtonDisable();
	}, [weekArray, isInPastOrder]);

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false;
		} else {
			setShowOverlay(loading);
		}
	}, [loading]);

	return (
		<div className={styles.date_selection_bar}>
			<div className='container'>
				{showOverlay && <div className={styles.overlay} />}
				<div className={styles.date_selector_wrapper}>
					<div className={styles.back_button_wrapper}>
						<span
							role='button'
							className={`${styles.arrow_style} ${
								backButtonDisabled ? styles.direction_disabled : ''
							} ${pastOrdersRedirect ? styles.direction_redirect : ''}`}
							onClick={onClickPastOrders}
							onKeyUp={() => {}}
							tabIndex={0}
						>
							<Tooltip
								position='bottom'
								label={
									backButtonDisabled ?
										t('End') :
										pastOrdersRedirect ?
											t('Past Orders') :
											t('Previous Week')
								}
							>
								{pastOrdersRedirect || backButtonDisabled ? (
									<i className={`icon-order_history ${styles.history_icon}`} />
								) : (
									<i className='icon-arrow_left' />
								)}
							</Tooltip>
						</span>
					</div>
					<div className={styles.dates_container}>
						{weekArray?.length ? (
							weekArray.map((dateData, index) => {
								const isSelected = selectedDate.isSame(
									dateData.dateObject,
									'day'
								);
								return (
									<DateCard
										key={index.toString()}
										sticker={getStickerData(dateData)}
										dateData={dateData}
										// dateDayString={`${dateData.date} ${dateData.day}`}
										isBlackedOut={dateData.blackout}
										isDateSelected={!isInPastOrder && isSelected}
										onClickHandler={() => onClickSingleDate(dateData)}
									/>
								);
							})
						) : (
							<div className={styles.no_more_dates} />
						)}
					</div>
					<span
						role='button'
						className={`${styles.arrow_style} ${
							forwardButtonDisabled ? styles.direction_disabled : ''
						}`}
						onClick={onClickFutureOrders}
						onKeyUp={() => {}}
						tabIndex={0}
					>
						<Tooltip
							label={forwardButtonDisabled ? t('End') : t('Next Week')}
							className={styles.tooltip}
						>
							<i className='icon-arrow_right' />
						</Tooltip>
					</span>
				</div>
			</div>
		</div>
	);
};

DateSelector.defaultProps = {
	onClickBack: () => {},
	onClickForward: () => {},
	onClickDay: () => {},
	onChangeWeek: () => {},
	loading: false,
	orderData: [],
	aDM: []
};

DateSelector.propTypes = {
	onClickBack: PropTypes.func,
	onClickForward: PropTypes.func,
	onClickDay: PropTypes.func,
	onChangeWeek: PropTypes.func,
	loading: PropTypes.bool,
	orderData: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line
	aDM: PropTypes.arrayOf(PropTypes.object) // eslint-disable-line
};

export default memo(DateSelector);
