const checkValidity = storageKeyOrValue => {
	const isValid = !!storageKeyOrValue && storageKeyOrValue !== 'null';
	return isValid;
};

const makeResult = (status, data, error) => ({
	status,
	data,
	error
});

export const setLocalStorage = (key, value) => {
	let stringToStore = '';

	if (!checkValidity(key)) {
		return makeResult(false, null, 'Invalid key supplied for value storage');
	}

	if (!checkValidity(value)) {
		return makeResult(false, null, 'Invalid value being stored. Please provide a valid number, string, object, boolean, array or JSON');
	}

	if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
		stringToStore = value;
	} else {
		stringToStore = JSON.stringify(value);
	}

	try {
		localStorage.setItem(key, stringToStore);
		return makeResult(true, stringToStore, null);
	} catch (e) {
		return makeResult(false, null, e);
	}
};

export const getLocalStorage = key => {
	if (!checkValidity(key)) {
		return makeResult(false, null, 'Invalid key for data retrieval');
	}

	try {
		const data = localStorage.getItem(key);
		if (!checkValidity(data)) {
			return makeResult(false, null, 'Invalid data retrieved');
		}
		return makeResult(true, data, null);
	} catch (e) {
		return makeResult(false, null, e);
	}
};

export const clearLocalStorage = key => {
	if (!checkValidity(key)) {
		return makeResult(false, null, 'Invalid key supplied for deletion of key');
	}

	try {
		localStorage.removeItem(key);
		return makeResult(true, key, null);
	} catch (e) {
		return makeResult(false, null, e);
	}
};
